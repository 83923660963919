import { SidebarItem, SidebarScrollWrapper } from "@backstage/core-components"
import KafkaIcon from "../Root/KafkaIcon";
import StorageIcon from '@material-ui/icons/Storage';
import ExtensionIcon from '@material-ui/icons/Extension';
import HomeIcon from '@material-ui/icons/Home';
import SearchIcon from '@material-ui/icons/Search';
import LibraryBooks from '@material-ui/icons/LibraryBooks';
import NoteAddIcon from '@material-ui/icons/NoteAdd';
import React from "react"
import { RBAC, useAuth } from "@internal/backstage-plugin-common-react";

export const SideBarItems = () => {

    const dataTabAccess = useAuth(RBAC.FEATURES.DEV_PORTAL, RBAC.MODULES.DATA_TAB)
    const eventsAccess = useAuth(RBAC.FEATURES.DEV_PORTAL, RBAC.MODULES.EVENTS)
    return (<>
        <SidebarItem icon={SearchIcon} to="/search" text="Search" />
        <SidebarItem icon={HomeIcon} to="/" text="Home" />
        <SidebarItem icon={LibraryBooks} to="docs" text="Documentation" />
        <SidebarItem icon={ExtensionIcon} to="zuplo" text="APIs" />
        {eventsAccess && eventsAccess.authenticatedPermissions.canRead && 
        <SidebarItem icon={KafkaIcon} to="confluentkafka" text="Events" />}
        {dataTabAccess && dataTabAccess.authenticatedPermissions.canRead && <SidebarItem icon={StorageIcon} to="dataassets" text="Data" />}
        <SidebarItem icon={NoteAddIcon} to="releaseNotes" text="Release Notes" />
        <SidebarScrollWrapper />
    </>)
}