import { createRouteRef } from '@backstage/core-plugin-api';

export const homePageRouteRef = createRouteRef({
  id: 'zuplo-home-page',
});

export const apiDetailsRouteRef = createRouteRef({
  id: 'zuplo-api-details',
  params: ['keyword']
});
