import React, { PropsWithChildren } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { Auth0Login } from '../Auth0Login/Auth0Login';
import { DevPortalPage } from '../DevPortalPage/DevPortalPage';

export const Root = ({ children }: PropsWithChildren<{}>) => {
  const { isAuthenticated } = useAuth0();
  return (<>{!isAuthenticated ? <><Auth0Login /></> :
  <DevPortalPage children={children}/>
    }
  </>
  )
}
