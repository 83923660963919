import React, { useEffect, useState } from 'react';
import {
  Header,
  Page,
  Content} from '@backstage/core-components';
import docs from './doc.md'
import ReactMarkdown from 'react-markdown'
import { CustomHeader } from '@internal/backstage-plugin-common-react';

export const Documentation = () => {
  const [docText, setDocText] = useState('')
  useEffect(() => {
		fetch(docs).then(res => res.text()).then(text => setDocText(text))
	})
  return (

    <Page themeId="tool">
      <><CustomHeader title="Documentation"/>
        <Content>
        <ReactMarkdown  children={docText}/>
        </Content>
      </>
    </Page>
  );
};
