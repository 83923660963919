import {
    createBaseThemeOptions,
    createUnifiedTheme,
    palettes,
  } from '@backstage/theme';
  
  export const myTheme = createUnifiedTheme({
    ...createBaseThemeOptions({
      palette: {
        ...palettes.light,
         background: {
        // default: '#d5d6db',
        // paper: '#d5d6db',
      },
      },
      
      
    }),
    fontFamily: 'Montserrat, sans-serif' ,
    defaultPageTheme: 'home',
  });