import { useState, useEffect } from "react";
import { userCurrentRolePermissionState } from "../atom";
import { useRecoilValue } from "recoil";
import { Permission } from "../type";

export const useAuth = (featureName: string, moduleName: string) => {
  
    const permissions = useRecoilValue(userCurrentRolePermissionState);
    const [authenticatedPermissions, setAuthenticatedPermissions] = useState<Partial<Permission>>({});
    
    useEffect(() => {
      if (permissions) {
        const matchedPermission = permissions.find(
          (rmp) => rmp.featureName === featureName && rmp.moduleName === moduleName
        );
  
        if (matchedPermission) {
          setAuthenticatedPermissions(matchedPermission);
        }
      }
    }, [featureName, moduleName, permissions]);
    return { authenticatedPermissions };
  }