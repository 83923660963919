import React, { useEffect, useState } from 'react';
import {
  Page,
  Content} from '@backstage/core-components';
import { CustomHeader } from '@internal/backstage-plugin-common-react'
import docs from './doc.md'
import ReactMarkdown from 'react-markdown'

export const HomePage = () => {
  const [docText, setDocText] = useState('')
  useEffect(() => {
		fetch(docs).then(res => res.text()).then(text => setDocText(text))
	})

  return (

    <Page themeId="tool">
      <><CustomHeader title=""/>
        <Content>
        <ReactMarkdown  children={docText}/>
        </Content>
      </>
    </Page>
  );
};
