import { createRouteRef } from '@backstage/core-plugin-api';

export const rootRouteRef = createRouteRef({
  id: 'confluentkafka',
});

export const kafkaDetailsRouteRef = createRouteRef({
  id: 'confluentkafka-details',
  params: ['keyword']
});
