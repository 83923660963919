import { makeStyles } from "@material-ui/core";
import React from 'react';

const useStyles = makeStyles({
    svg: {
      width: 'auto',
      height: 25,
    },
    path: {
      fill: '#7df3e1',
    },
  });

const KafkaIcon =() =>{
    const classes = useStyles();
    return (<svg  className={classes.svg} viewBox="0 0 86 139" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="23.5" cy="70.5" r="18" stroke="white" strokeWidth="11"/>
    <circle cx="24" cy="18" r="12.5" stroke="white" strokeWidth="11"/>
    <circle cx="68" cy="44" r="12.5" stroke="white" strokeWidth="11"/>
    <circle cx="68" cy="96" r="12.5" stroke="white" strokeWidth="11"/>
    <circle cx="24" cy="121" r="12.5" stroke="white" strokeWidth="11"/>
    <line x1="24" y1="35" x2="24" y2="49" stroke="white" strokeWidth="10"/>
    <line x1="54.5905" y1="51.4504" x2="42.7879" y2="58.9801" stroke="white" strokeWidth="10"/>
    <line x1="41.4708" y1="80.1549" x2="53.5656" y2="87.2059" stroke="white" strokeWidth="10"/>
    <line x1="24" y1="91" x2="24" y2="105" stroke="white" strokeWidth="10"/>
    </svg>
    )
    
}
export default KafkaIcon