import { useAuth0 } from "@auth0/auth0-react";
import { Progress } from "@backstage/core-components";
import {  
  Button,
  Typography,
  styled,
  Container,
  Box
} from "@material-ui/core";
import React from "react";
import { useState } from "react";
import {
  configApiRef,
  useApi
} from '@backstage/core-plugin-api';
import DaraIcon from '../../components/Root/DaraIcon';
 
const BackgroundContainer = styled(Container)({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  height: '100vh',
  backgroundColor: '#f2f2f2',
});
 
const LoginBox = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  padding: '60px',
  borderRadius: '15px',
  backgroundColor: 'white',
});
 
export const Auth0Login = () => {
    const [showLoginPage, setShowLoginPage] = useState<boolean>(true);
    const {isAuthenticated,loginWithRedirect} = useAuth0();
    const configApi = useApi(configApiRef);
    const authLogin = () => {
      loginWithRedirect().then(() => {
          setShowLoginPage(false)
      })
    }
   
    return (isAuthenticated || showLoginPage ? (<>
    <BackgroundContainer>
    <LoginBox>
      <Box 
        display="flex" 
        alignItems="center" 
        mb={2}>
        <Typography 
          variant="h3" 
          style={{"paddingRight": 10, "paddingTop": 5}}>
            {configApi.getString('app.title')}
        </Typography>
        <DaraIcon />
      </Box>
      <Button 
        variant="contained"
        color="primary"
        fullWidth
        style={{textTransform: 'none', padding: '10px'}}
        onClick={() => {
          authLogin();
          }
        }>
        Log In With SSO
      </Button>
    </LoginBox>
    </BackgroundContainer>
  </>
) : (
  <Progress />
) );
}