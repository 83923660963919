import { createRouteRef } from '@backstage/core-plugin-api';

export const rootRouteRef = createRouteRef({
  id: 'data-assets',
});

export const dataDetailsRouteRef = createRouteRef({
  id: 'data-assets',
  params: ['keyword']
});
