import { HeaderLabel, Header} from "@backstage/core-components"
import { Typography, IconButton, Menu, MenuItem } from "@material-ui/core"
import React from "react"
import { useNavigate } from "react-router";
import MoreVertIcon from '@material-ui/icons/MoreVert';
import { useAuth0 } from '@auth0/auth0-react';

interface ICustomHeader{
  title?: React.ReactNode
  type?: string;
  typeLink?: string;
}

export const CustomHeader = (prop : ICustomHeader) => {
    const { user,logout } = useAuth0();
    const [open, setOpen] = React.useState(false);
    const [anchorEl, setAnchorEl] = React.useState<undefined | HTMLElement>(
      undefined,
    );
    const navigate = useNavigate();
    const handleOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
      setAnchorEl(event.currentTarget);
      setOpen(true);
    };
  
    const handleClose = () => {
      setAnchorEl(undefined);
      setOpen(false);
    };
   
    
    return (
      <>
        <Header
          title={prop.title}
          type={prop.type}
          typeLink={prop.typeLink}
          subtitle=""
          style={{ backgroundColor: '#1DA1F2', backgroundImage: 'none' }}
        >
          <HeaderLabel
            label=""
            value={<Typography variant="body1">{user?.email}</Typography>}
          />
          <IconButton aria-label="more" onClick={handleOpen}>
            <MoreVertIcon />
          </IconButton>
          <Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
            <MenuItem onClick={() => navigate('/settings')}>
              View Profile
            </MenuItem>
            <MenuItem
              data-testid="sign-out"
              onClick={() => logout({ logoutParams: { returnTo: window.location.origin } })}
            >
              Sign Out
            </MenuItem>
          </Menu>
        </Header>
      </>
    );
}
