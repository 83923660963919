import { useAuth0 } from "@auth0/auth0-react";
import {
    SidebarPage, Sidebar, SidebarDivider, SidebarGroup, SidebarItem, 
    SidebarSpace, useSidebarOpenState, sidebarConfig, Link
} from "@backstage/core-components";
import React, { PropsWithChildren, useEffect } from "react";
import MenuIcon from '@material-ui/icons/Menu';
import SettingsIcon from '@material-ui/icons/Settings';
import DaraLogo from '../Root/DaraLogo';
import DaraIcon from '../Root/DaraIcon';
import { makeStyles } from "@material-ui/core";
import { useApi, discoveryApiRef, fetchApiRef, alertApiRef } from "@backstage/core-plugin-api";
import  {userCurrentRolePermissionState } from "@internal/backstage-plugin-common-react";
import { useRecoilState } from "recoil";
import { SideBarItems } from "../SideBarItems/SideBarItems";


const useSidebarLogoStyles = makeStyles({
    root: {
        width: sidebarConfig.drawerWidthClosed,
        height: 3 * sidebarConfig.logoHeight,
        display: 'flex',
        flexFlow: 'row nowrap',
        alignItems: 'center',
        marginBottom: -14,
    },
    link: {
        width: sidebarConfig.drawerWidthClosed,
        marginLeft: 24,
    },
});
export const useStyles = makeStyles(
    {
        container: {
            padding: 0,
            listStyle: 'none',
        },
        item: {
            display: 'flex',
            flexDirection: 'column',
            width: '100%',
            maxWidth: '400px',
            margin: 0,
            padding: 0,
        },
    },
    { name: 'BackstageSignInPage' },
);

const SidebarLogo = () => {
    const classes = useSidebarLogoStyles();
    const { isOpen } = useSidebarOpenState();

    return (
        <div className={classes.root}>
            <Link to="/" underline="none" className={classes.link} aria-label="Home">
                {isOpen ? <DaraLogo /> : <DaraIcon />}
            </Link>
        </div>
    );
};

export const DevPortalPage = ({ children }: PropsWithChildren<{}>) => {

    const { user, getAccessTokenSilently } = useAuth0();
    const discoveryApi = useApi(discoveryApiRef);
    const { fetch } = useApi(fetchApiRef);
    const alertApi = useApi(alertApiRef);
    const [userCurrentRolePermissionValues, setUserCurrentRolePermissionValues] = useRecoilState(userCurrentRolePermissionState);
    useEffect(() => {
        const getRolesFromSagent = async (email: string| undefined) => {
            try {
                if(email){
                    const token = await getAccessTokenSilently();
                    const response = await fetch(
                        `${await discoveryApi.getBaseUrl('common')}/roles`,
                        {
                            method: 'POST',
                            headers: {
                                'Content-Type': 'application/json',
                            },
                            body: JSON.stringify({
                                user : email,
                                token : token
                            })
                        },
                    );
        
                    if (!response.ok) {
                        const { error } = await response.json();
                        alertApi.post({
                            message: error.message,
                            severity: 'error',
                        });
                    } 
                    
                    const data = await response.json();
                   setUserCurrentRolePermissionValues(data);
                          
                }
            } catch (error: any) {
                alertApi.post({ message: error.message, severity: 'error' });
            }
        }
      getRolesFromSagent(user?.email);  
    }, [alertApi, discoveryApi, fetch, getAccessTokenSilently, setUserCurrentRolePermissionValues, user?.email])
    return (<><SidebarPage>
        <Sidebar>
            <SidebarLogo />
            <SidebarDivider />
            <SidebarGroup label="Menu" icon={<MenuIcon />}>
              <SideBarItems/>
            </SidebarGroup>
            <SidebarSpace />
            <SidebarDivider />
            <SidebarItem icon={SettingsIcon} to="settings" text="Settings" />
        </Sidebar>
        {children}
    </SidebarPage></>
       
       
    );

}
