import {
  createPlugin,
  createRoutableExtension,
} from '@backstage/core-plugin-api';

import { createSearchResultListItemExtension } from '@backstage/plugin-search-react';


import { rootRouteRef,kafkaDetailsRouteRef } from './routes';

export const confluentkafkaPlugin = createPlugin({
  id: 'confluentkafka',
  routes: {
    root: rootRouteRef,
  },
});

export const ConfluentkafkaPage = confluentkafkaPlugin.provide(
  createRoutableExtension({
    name: 'ConfluentkafkaPage',
    component: () =>
      import('./components/KafkaComponent').then(m => m.KafkaComponent),
    mountPoint: kafkaDetailsRouteRef,
  }),
);

export const ConfluentkafkaTopicDetailPage = confluentkafkaPlugin.provide(
  createRoutableExtension({
    name: 'ConfluentkafkaTopicDetailPage',
    component: () =>
      import('./components/ConfluentkafkaTopic').then(m => m.ConfluentkafkaTopicDetail),
    mountPoint: rootRouteRef,
  }),
);

export const SearchResultListItemExtension = confluentkafkaPlugin.provide(
  createSearchResultListItemExtension({
    name: 'SearchResultListItem',
    component: () =>
      import('./components/SearchResultListItem').then(m => m.SearchResultListItem),
  }),
);
