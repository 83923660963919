import React from 'react';
import { Route } from 'react-router-dom';
import { apiDocsPlugin, ApiExplorerPage } from '@backstage/plugin-api-docs';
import {
  catalogPlugin,
} from '@backstage/plugin-catalog';
import {
  CatalogImportPage,
  catalogImportPlugin,
} from '@backstage/plugin-catalog-import';
import { ScaffolderPage, scaffolderPlugin } from '@backstage/plugin-scaffolder';
import { orgPlugin } from '@backstage/plugin-org';
import { SearchPage } from '@backstage/plugin-search';
import {
  techdocsPlugin
} from '@backstage/plugin-techdocs';
import { searchPage } from './components/search/SearchPage';
import { createApp } from '@backstage/app-defaults';
import { FlatRoutes } from '@backstage/core-app-api';
import { CatalogGraphPage } from '@backstage/plugin-catalog-graph';
import { RequirePermission } from '@backstage/plugin-permission-react';
import { catalogEntityCreatePermission } from '@backstage/plugin-catalog-common/alpha';
import { AssetsTableDetailsPage, DataAssetsPage, ReleaseNotes } from '@internal/backstage-plugin-data-assets';
import LightIcon from '@material-ui/icons/WbSunny';
import { UnifiedThemeProvider } from '@backstage/theme';
import { myTheme } from './themes/myTheme';
import { ZuploPortalApiDetailsPage, ZuploPortalHomePage } from '@internal/backstage-plugin-zuplo';
import { ConfluentkafkaTopicDetailPage, ConfluentkafkaPage } from '@internal/backstage-plugin-confluentkafka';
import { HomepageCompositionRoot } from '@backstage/plugin-home';
import { HomePage } from './components/Home/HomePage';
import { Documentation } from './components/Documentation';
import { SettingsLayout } from './components/Settings/SettingLayout';
import { UserSettingsGeneral } from './components/Settings/UserSettingsGeneral';
import { Auth0CustomeProvider } from './components/Root/Auth0CustomProvider';

const app = createApp({
  themes: [{
    id: 'my-theme',
    title: 'My Custom Theme',
    variant: 'light',
    icon: <LightIcon />,
    Provider: ({ children }) => (
      <UnifiedThemeProvider theme={myTheme} children={children} />
    ),
  }],
  bindRoutes({ bind }) {
    bind(catalogPlugin.externalRoutes, {
      createComponent: scaffolderPlugin.routes.root,
      viewTechDoc: techdocsPlugin.routes.docRoot,
      createFromTemplate: scaffolderPlugin.routes.selectedTemplate,
    });
    bind(apiDocsPlugin.externalRoutes, {
      registerApi: catalogImportPlugin.routes.importPage,
    });
    bind(scaffolderPlugin.externalRoutes, {
      registerComponent: catalogImportPlugin.routes.importPage,
      viewTechDoc: techdocsPlugin.routes.docRoot,
    });
    bind(orgPlugin.externalRoutes, {
      catalogIndex: catalogPlugin.routes.catalogIndex,
    });
  },
});

const routes = (
  <FlatRoutes>
    <Route path="/" element={<HomepageCompositionRoot />}>
      <HomePage />
    </Route>
    <Route path="/common" element={<HomepageCompositionRoot />}>
      <HomePage />
    </Route>
    <Route path="/docs" element={<HomepageCompositionRoot />}>
      <Documentation />
    </Route>
    <Route path="/create" element={<ScaffolderPage />} />
    <Route path="/api-docs" element={<ApiExplorerPage />} />
    <Route path="/catalog-import" element={
        <RequirePermission permission={catalogEntityCreatePermission}>
          <CatalogImportPage />
        </RequirePermission>
      }
    />
    <Route path="/search" element={<SearchPage />}>
      {searchPage}
    </Route>
    <Route path="/settings" element={
        <SettingsLayout>
          <SettingsLayout.Route path="general" title="General">
            <UserSettingsGeneral />
          </SettingsLayout.Route>
        </SettingsLayout>
      }
    />
    <Route path="/catalog-graph" element={<CatalogGraphPage />} />
    <Route path="/dataassets" element={<DataAssetsPage />} />
    <Route path="/dataassets/:keyword" element={<DataAssetsPage />} />
    <Route path="/dataassets/detail" element={<AssetsTableDetailsPage />} />
    <Route path="/dataassets/detail/:keyword" element={<AssetsTableDetailsPage />} />
    <Route path="/dataassets/dara-core-metadata" element={<AssetsTableDetailsPage />} />
    <Route path="/zuplo" element={<ZuploPortalHomePage />} />
    <Route path="/zuplo/apis" element={<ZuploPortalHomePage />} />
    <Route path="/zuplo/apis/:keyword" element={<ZuploPortalApiDetailsPage />} />
    <Route path="/confluentkafka" element={<ConfluentkafkaPage />} />
    <Route path="/confluentkafka/:keyword" element={<ConfluentkafkaPage />} />
    <Route path="/confluentkafka/topic/detail" element={<ConfluentkafkaTopicDetailPage />} />
    <Route path="/releaseNotes" element={<ReleaseNotes />} />
  </FlatRoutes>
);

export default app.createRoot(
  <>
    <Auth0CustomeProvider>{routes}</Auth0CustomeProvider>
  </>,
);
