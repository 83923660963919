import { makeStyles } from "@material-ui/core";
import React from 'react';

const useStyles = makeStyles({
    svg: {
      width: 'auto',
      height: 30,
    },
    path: {
      fill: '#7df3e1',
    },
  });

const DaraIcon =() =>{
    const classes = useStyles();
    return (<svg width="80" className={classes.svg} height="80" viewBox="0 0 80 80" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M55.8404 64.1572V33.4512H48.8634V64.1572C48.8634 69.0431 44.8851 73.0219 39.9879 73.0219C35.1028 73.0219 31.1246 69.0431 31.1246 64.1572V58.1789H24.1475V64.1572C24.1475 72.8929 31.2535 80 39.9879 80C48.7344 80 55.8404 72.8929 55.8404 64.1572Z" fill="#1DA1F2"/>
    <path d="M33.4543 24.1472V31.1252H64.1475C69.0326 31.1252 73.023 35.1162 73.0189 40.002C73.0189 44.9 69.0286 48.8788 64.1475 48.8788H58.1701V55.8569H64.1475C72.8819 55.8569 80 48.7498 80 40.002C80 31.2663 72.8819 24.1472 64.1475 24.1472H33.4543Z" fill="#53565A"/>
    <path d="M15.8484 55.8529H46.5417V48.8748H15.8525C10.9553 48.8748 6.97703 44.896 6.97703 39.998C6.97703 35.1121 10.9553 31.1212 15.8525 31.1212H21.8299V24.1431H15.8525C7.10601 24.1431 1.71383e-06 31.2623 1.71383e-06 39.998C-0.00402892 48.7458 7.10198 55.8529 15.8484 55.8529Z" fill="#1DA1F2"/>
    <path d="M24.1516 46.5487H31.1286V15.8549C31.1286 10.9569 35.1068 6.97808 39.992 6.97808C44.8892 6.97808 48.8634 10.9569 48.8634 15.8549V21.8211H55.8404V15.8549C55.8404 7.10708 48.7344 0 39.9879 0C31.2535 0 24.1475 7.10708 24.1475 15.8549V46.5487H24.1516Z" fill="#1DA1F2"/>
    </svg>)
    
}
export default DaraIcon