import React from 'react';
import { Grid, Paper } from '@material-ui/core';
import {
  SearchBar,
  SearchResult
} from '@backstage/plugin-search-react';
import {
  Content,
  Page
} from '@backstage/core-components';
import {List} from "@material-ui/core";
import { CustomHeader } from '@internal/backstage-plugin-common-react';
import { SearchResultListItemExtension } from '@internal/backstage-plugin-confluentkafka';
import {ZuploConstants} from '@internal/backstage-plugin-zuplo-common';
import { RBAC, useAuth, SEARCH } from "@internal/backstage-plugin-common-react";


const SearchPage = () => {

  const cmodAccess  = useAuth(RBAC.FEATURES.DEV_PORTAL, RBAC.MODULES.DATA_CMOD)?.authenticatedPermissions?.canRead;
  const adminApiAccess = useAuth(RBAC.FEATURES.DEV_PORTAL, RBAC.MODULES.API_ADMIN)?.authenticatedPermissions?.canRead;
  const boardingApiAccess = useAuth(RBAC.FEATURES.DEV_PORTAL, RBAC.MODULES.API_BOARDING)?.authenticatedPermissions?.canRead;
  const complianceApiAccess = useAuth(RBAC.FEATURES.DEV_PORTAL, RBAC.MODULES.API_COMPLIANCE)?.authenticatedPermissions?.canRead;
  const escrowApiAccess = useAuth(RBAC.FEATURES.DEV_PORTAL, RBAC.MODULES.API_ESCROW)?.authenticatedPermissions?.canRead;
  const loanApiAccess = useAuth(RBAC.FEATURES.DEV_PORTAL, RBAC.MODULES.API_LOANS)?.authenticatedPermissions?.canRead;
  const paymentsApiAccess = useAuth(RBAC.FEATURES.DEV_PORTAL, RBAC.MODULES.API_PAYMENTS)?.authenticatedPermissions?.canRead;
  const adminEventAccess  = useAuth(RBAC.FEATURES.DEV_PORTAL, RBAC.MODULES.EVENT_ADMIN)?.authenticatedPermissions?.canRead;
  const escrowEventAccess  = useAuth(RBAC.FEATURES.DEV_PORTAL, RBAC.MODULES.EVENT_ESCROW)?.authenticatedPermissions?.canRead;
  const loanEventAccess  = useAuth(RBAC.FEATURES.DEV_PORTAL, RBAC.MODULES.EVENT_LOAN)?.authenticatedPermissions?.canRead;
  const paymentsEventAccess  = useAuth(RBAC.FEATURES.DEV_PORTAL, RBAC.MODULES.EVENT_PAYMENTS)?.authenticatedPermissions?.canRead;
  const complianceEventAccess  = useAuth(RBAC.FEATURES.DEV_PORTAL, RBAC.MODULES.EVENT_COMPLIANCE)?.authenticatedPermissions?.canRead;
  const boardingEventAccess  = useAuth(RBAC.FEATURES.DEV_PORTAL, RBAC.MODULES.EVENT_BOARDING)?.authenticatedPermissions?.canRead;
  
  return (
    <Page themeId="home">
    <CustomHeader title="Search" />
    <Content>
      <Grid container direction="row">
        <Grid item xs={12}>
          <Paper>
            <SearchBar />
          </Paper>
        </Grid>
        <Grid item xs={12}>
          <SearchResult>
          {({ results }) => (
        <List>
          {results.map(({ type, document}) => {
            switch (type) {
              case SEARCH.DATA_SEARCH:
                if(cmodAccess) {
                  return (
                  <SearchResultListItemExtension
                    result={document}
                  />
                );
                }
                break;
                case SEARCH.API_SEARCH:
                  if((adminApiAccess && document?.subType === ZuploConstants.ADMIN_KEYWORD) ||
                    (boardingApiAccess && document?.subType === ZuploConstants.BOARDING_KEYWORD) ||
                    (complianceApiAccess && document?.subType === ZuploConstants.COMPLIANCE_KEYWORD) ||
                    (escrowApiAccess && document?.subType === ZuploConstants.ESCROW_KEYWORD) ||
                    (loanApiAccess && document?.subType === ZuploConstants.LOANS_KEYWORD) ||
                    (paymentsApiAccess && document?.subType === ZuploConstants.PAYMENTS_KEYWORD)
                   )
                    {
                    return (
                    <SearchResultListItemExtension
                      result={document}
                    />
                  );
                  }
                  break;
                case SEARCH.KAFKA_SEARCH:
                  if((adminEventAccess && document?.tags === ZuploConstants.ADMIN_TITLE) ||
                    (boardingEventAccess && document?.tags === ZuploConstants.BOARDING_TITLE) ||
                    (complianceEventAccess && document?.tags === ZuploConstants.COMPLIANCE_TITLE) ||
                    (escrowEventAccess && document?.tags === ZuploConstants.ESCROW_TITLE) ||
                    (loanEventAccess && document?.tags === ZuploConstants.LOANS_TITLE) ||
                    (paymentsEventAccess && document?.tags === ZuploConstants.PAYMENTS_TITLE)
                   )
                    {
                    return (
                    <SearchResultListItemExtension
                      result={document}
                    />
                  );
                  }
                  break;
              default:
                return (
                  <SearchResultListItemExtension
                    result={document}
                  />
                );
            }
          })}
        </List>
      )}
          </SearchResult>
        </Grid>
      </Grid>
    </Content>
  </Page>
  );
};

export const searchPage = <SearchPage />;
