import { makeStyles } from "@material-ui/core";
import React from 'react';

const useStyles = makeStyles({
    svg: {
      width: 'auto',
      height: 30,
    },
    path: {
      fill: '#7df3e1',
    },
  });

const DaraLogo =() =>{
    const classes = useStyles();
     return(<svg width="273" className={classes.svg} height="95" viewBox="0 0 273 95" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M18.008 28.4512H0V65.7873H18.008C28.3106 65.7873 36.6814 57.4164 36.6814 47.1139C36.6814 36.8256 28.3106 28.4512 18.008 28.4512ZM18.008 57.5667H8.22059V36.6718H18.008C23.7746 36.6718 28.4608 41.358 28.4608 47.1139C28.4608 52.8804 23.7746 57.5667 18.008 57.5667Z" fill="#1DA1F2"/>
    <path d="M38.6768 47.1139C38.6768 57.4164 47.0476 65.7873 57.3502 65.7873H75.3582V28.4512H57.3502C47.0476 28.4512 38.6768 36.822 38.6768 47.1139ZM46.8974 47.1139C46.8974 41.358 51.5836 36.6718 57.3502 36.6718H67.1376V57.5667H57.3502C51.58 57.5667 46.8974 52.8804 46.8974 47.1139Z" fill="#1DA1F2"/>
    <path d="M117.317 47.1139C117.317 57.4164 125.688 65.7873 135.991 65.7873H153.999V28.4512H135.991C125.688 28.4512 117.317 36.822 117.317 47.1139ZM125.542 47.1139C125.542 41.358 130.228 36.6718 135.994 36.6718H145.782V57.5667H135.994C130.224 57.5667 125.542 52.8804 125.542 47.1139Z" fill="#1DA1F2"/>
    <path d="M96.6487 28.4512H78.6406V65.7873H86.8612V36.6718H96.6487C102.415 36.6718 107.101 41.358 107.101 47.1139H115.322C115.322 36.8256 106.951 28.4512 96.6487 28.4512Z" fill="#1DA1F2"/>
    <path d="M243.786 75.5742V39.4043H235.566V75.5742C235.566 81.33 230.879 86.0163 225.113 86.0163C219.357 86.0163 214.671 81.33 214.671 75.5778V68.5341H206.45V75.5778C206.45 85.866 214.821 94.2404 225.113 94.2404C235.415 94.2404 243.786 85.8696 243.786 75.5778V75.5742Z" fill="white"/>
    <path d="M217.414 28.4395V36.66H253.573C259.329 36.66 264.026 41.3606 264.026 47.1129C264.026 52.8794 259.325 57.5657 253.573 57.5657H246.53V65.7863H253.573C263.861 65.7863 272.247 57.4154 272.247 47.1129C272.247 36.8246 263.861 28.4395 253.573 28.4395H217.414Z" fill="#1DA1F2"/>
    <path d="M196.669 65.7902H232.828V57.5696H196.672C190.906 57.5696 186.22 52.8833 186.22 47.1168C186.22 41.3609 190.906 36.664 196.672 36.664H203.716V28.4434H196.672C186.37 28.4434 177.999 36.8285 177.999 47.1168C177.999 57.4193 186.37 65.7902 196.672 65.7902H196.669Z" fill="white"/>
    <path d="M206.452 54.829H214.673V18.6734C214.673 12.9068 219.359 8.22059 225.115 8.22059C230.881 8.22059 235.568 12.9068 235.568 18.6734V25.7028H243.788V18.6734C243.788 8.37084 235.417 0 225.115 0C214.827 0 206.452 8.37084 206.452 18.6734V54.8326V54.829Z" fill="white"/>
    </svg>
    )
    
}
export default DaraLogo