export const RBAC = {
    FEATURES: {
        DEV_PORTAL: 'Dev Portal'
    },
    ACCESS: {
        READ: 'Read',
        UPDATE: 'Update',
        CREATE: 'Create',
        DELETE: 'Delete',
        LIST: 'List',
    },
    MODULES: {
        HOME_TAB: 'Home',
        // API
        API_ADMIN: 'API-Admin',
        API_BOARDING: "API-Boarding",
        API_LOANS: "API-Loans",
        API_ESCROW: 'API-Escrow',
        API_COMPLIANCE: 'API-Compliance',
        API_PAYMENTS: 'API-Payments',
        
        // EVENT
        EVENTS: "Events",
        EVENT_ADMIN: 'Events-Admin',
        EVENT_ESCROW: 'Events-Escrow',
        EVENT_LOAN: 'Events-Loans',
        EVENT_PAYMENTS: 'Events-Payments',
        EVENT_COMPLIANCE: 'Events-Compliance',
        EVENT_BOARDING: 'Events-Boarding',
        
        // Data
        DATA_SG_CORE: 'Data-sg_core',
        DATA_CMOD: 'Data-cmod',
        DATA_CIRRUS: 'Data-cirrus',
        DATA_CLAIMS: 'Data-claims',
        DATA_ATLAS: 'Data-atlas',
        DATA_TAB: 'Data'
      }
}